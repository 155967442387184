import storage from 'local-storage-fallback'

export const changeSelectedRegion = (region) => {
    return (dispatch) => {
        storage.setItem('selectedRegion', region);

        dispatch({
            type: 'SET_REGION',
            region
        })
    }
}