import React from "react";
import adminLayout from "./../hoc/adminLayout"
import requireAuth from "../hoc/requireAuth";
import "./../assets/css/lead-list.css";
import "./../assets/css/drug-detail-page.css";
import { withAlert } from 'react-alert';
import queryString from 'query-string';
import ModalComponent from "../components/ModalComponent";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import * as moment from 'moment'

class DrugDetailPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            recordId: queryString.parse(this.props.location.search).drug,
            drugOverviewData: null,
            apiManufacturingData: [],
            ipUSA: [1],
            labelData: [2],
            csData: [3],
            spcData: [],
            ipEurope: [],
            apiManufacturingStatusFilter: {},
            selectedApiManufacturingDetail: null,
            selectedLabelDetail: null,
            selectedCSDetail: null,
            launchedDrugsFilter: {},
            ipUSFilter: {},
            ipEuropeFilter: {},
            spcFilter: {},
            suggestionsList: [],
            clinicalStudiesFilter: {},
            sorting: {
                sortBy: '',
                sortIconClass: 'sort-icon-nutral',
                sortOrder: ''
            },
            spcSorting: {
                sortBy: '',
                sortIconClass: 'sort-icon-nutral',
                sortOrder: ''
            },
            clinicalStudiesSorting: {
                sortBy: '',
                sortIconClass: 'sort-icon-nutral',
                sortOrder: ''
            },
            launchedDrugsSorting: {
                sortBy: '',
                sortIconClass: 'sort-icon-nutral',
                sortOrder: ''
            },
            ipUSSorting: {
                sortBy: '',
                sortIconClass: 'sort-icon-nutral',
                sortOrder: ''
            },
            ipEuropeSorting: {
                sortBy: '',
                sortIconClass: 'sort-icon-nutral',
                sortOrder: ''
            },
            sortingCollection: '',
        }
    }

    renderFilterPopover(columnName, collection){
        return (
          <Popover id="popover-positioned-right" title="Popover right">
            <div className="container py-1 px-1">
              {/* <div className="row">
                <div className="col-7 mx-0">
                    <input onChange={(e) => this.changeFilterValue(columnName, e.target.value, collection) } value={this.getFilterInputValue(columnName, collection)} className='form-control' />
                </div>
                <div className="col-5 mx-0">
                    <button onClick={(e) => this.fetchFilterData(collection)}  className='btn btn-success popover-btn'>Apply Filter</button>
                </div>
              </div> */}
              <div className="row">
                <div className="col-12">
                    {(this.state.suggestionsList.length > 0) && <span className="suggestions-heading">Suggestions:</span>}
                    {(this.state.suggestionsList.length > 0) && <ul className="list-unstyled suggestion-list">
                        {this.state.suggestionsList.map((suggestion, index) => 
                            <li key={index}>
                                {(columnName === 'Estimated Expiry' || columnName === 'Est Expiry') && <label for={`suggestion-${index}`}>{moment(suggestion).format('DD-MM-YYYY')}</label>}
                                {(columnName !== 'Estimated Expiry' && columnName !== 'Est Expiry' ) && <label for={`suggestion-${index}`}>{suggestion}</label>}
                                &nbsp;<input id={`suggestion-${index}`} checked={Array.isArray(this.state[this.getFilterVar(collection)][columnName]) && this.state[this.getFilterVar(collection)][columnName].includes(suggestion)} onChange={(e) => this.clickSuggestion(e, columnName, suggestion, collection)} type="checkbox" />
                            </li>
                        )}
                    </ul>}
                </div>
              </div>
            </div>
            
          </Popover>
        )
    }

    fetchFilterData(collection){
        if(collection === 'launchedDrugs'){
            return this.fetchLabel();
        }

        if(collection === 'spc'){
            return this.fetchSPC();
        }

        if(collection === 'clinicalStudies'){
            return this.fetchCS();
        }

        if(collection === 'ipEurope'){
            return this.fetchIPEurope();
        }

        if(collection === 'ipUS'){
            return this.fetchIPUSA();
        }

        return this.fetchApiManufacturing();
    }

    getFilterVar(collection){
        let filterVar = 'apiManufacturingStatusFilter';

        if(collection === 'launchedDrugs'){
            filterVar = 'launchedDrugsFilter';
        }

        if(collection === 'spc'){
            filterVar = 'spcFilter';
        }

        if(collection === 'clinicalStudies'){
            filterVar = 'clinicalStudiesFilter';
        }

        if(collection === 'ipEurope'){
            filterVar = 'ipEuropeFilter';
        }

        if(collection === 'ipUS'){
            filterVar = 'ipUSFilter';
        }

        return filterVar;
    }

    getFilterInputValue(columnName, collection){
        return this.state[this.getFilterVar(collection)][columnName];
    }

    clickSuggestion(e, columnName, suggestion, collection){
        this.changeFilterValue(e, columnName, suggestion, collection)
        this.fetchFilterData(collection)
    }

    changeFilterValue(e, columnName, value, collection){
        let filterVar = this.getFilterVar(collection)
        let filters = this.state[filterVar];
        let filterColumn = filters[columnName];
        
        if(!filterColumn){
            filterColumn = []
        }
        
        if(e.target.checked && !filterColumn.includes(value)){
            filterColumn.push(value)
        }

        if(!e.target.checked && filterColumn.includes(value)){
            var index = filterColumn.indexOf(value);
            if (index !== -1) {
                filterColumn.splice(index, 1);
            }
        }

        if(filterColumn.length < 1){
            filterColumn = undefined
        }
        
        filters[columnName] = filterColumn;

        this.setState(() => ({
            [filterVar]:filters
        }))
    }

    changeSorting(sortBy, collection = ''){
        
        let sortingVarName = 'sorting';
            
        if(collection === 'spc'){
            sortingVarName = 'spcSorting';
        }

        if(collection === 'clinicalStudies'){
            sortingVarName = 'clinicalStudiesSorting';
        }

        if(collection === 'launchedDrugs'){
            sortingVarName = 'launchedDrugsSorting';
        }

        if(collection === 'ipEurope'){
            sortingVarName = 'ipEuropeSorting';
        }

        if(collection === 'ipUS'){
            sortingVarName = 'ipUSSorting';
        }

        this.setState((prevState) => {
    
            const {sortOrder, classPostFix} = this.getNewSortOrder(prevState[sortingVarName].sortOrder);

            return {
                [sortingVarName]: {
                    sortBy,
                    sortIconClass: `sort-icon-${classPostFix}`,
                    sortOrder,
                },
                sortingCollection: collection
            }
        })
    }

    getNewSortOrder = (prevSortOrder) => {
        let sortOrder = ''
        let classPostFix = 'nutral'
      
        if(prevSortOrder === ''){
            sortOrder = 'asc'
            classPostFix = 'asc'
        }
      
        if(prevSortOrder === 'asc'){
            sortOrder = 'desc'
            classPostFix = 'desc'
        }
      
        if(prevSortOrder === 'desc'){
            sortOrder = ''
            classPostFix = 'nutral'
        }
      
        return {
            sortOrder, classPostFix
        }
    }

    componentDidMount(){
        this.fetchDrugOverview();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.drugOverviewData !== this.state.drugOverviewData && this.state.drugOverviewData){
            this.fetchApiManufacturing();
            this.fetchIPUSA();
            this.fetchLabel();
            this.fetchCS();
            this.fetchIPEurope();
            this.fetchSPC();
        }

        if(prevProps.region !== this.props.region){
            return this.props.history.goBack();
        }

        let sortingVarName = 'sorting';

        if(this.state.sortingCollection === 'spc'){
            sortingVarName = 'spcSorting';
        }

        if(this.state.sortingCollection === 'clinicalStudies'){
            sortingVarName = 'clinicalStudiesSorting';
        }

        if(this.state.sortingCollection === 'launchedDrugs'){
            sortingVarName = 'launchedDrugsSorting';
        }

        if(this.state.sortingCollection === 'ipEurope'){
            sortingVarName = 'ipEuropeSorting';
        }

        if(this.state.sortingCollection === 'ipUS'){
            sortingVarName = 'ipUSSorting';
        }
        
        if(prevState[sortingVarName].sortBy !== this.state[sortingVarName].sortBy || prevState[sortingVarName].sortOrder !== this.state[sortingVarName].sortOrder){
            if(this.state.sortingCollection === ''){
                this.fetchApiManufacturing();
            }

            if(this.state.sortingCollection === 'spc'){
                this.fetchSPC();
            }

            if(this.state.sortingCollection === 'clinicalStudies'){
                this.fetchCS();
            }

            if(this.state.sortingCollection === 'launchedDrugs'){
                this.fetchLabel();
            }

            if(this.state.sortingCollection === 'ipEurope'){
                this.fetchIPEurope();
            }
            
            if(this.state.sortingCollection === 'ipUS'){
                this.fetchIPUSA();
            }
        }
    }

    fetchApiManufacturing(){
        window.axios.get(`/drugs/api-manufacturing/${this.state.recordId}`, {
            params: {
                sortBy: this.state.sorting.sortBy,
                sortOrder: this.state.sorting.sortOrder,
                filters: {...this.state.apiManufacturingStatusFilter}
            }
        })
        .then((response) => {
            let apiManufacturingData = response.data.docs;

            this.setState(() => ({
                apiManufacturingData
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching data.');
        })
    }

    fetchIPUSA(){
        window.axios.get(`/drugs/ip-usa/${this.state.recordId}`, {
            params : {
                sortBy: this.state.ipUSSorting.sortBy,
                sortOrder: this.state.ipUSSorting.sortOrder,
                filters: {...this.state.ipUSFilter}
            }
        })
        .then((response) => {
            let ipUSA = response.data.docs;

            this.setState(() => ({
                ipUSA
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching data.');
        })
    }

    fetchLabel(){
        window.axios.get(`/drugs/label/${this.state.recordId}`,{
            params: {
                sortBy: this.state.launchedDrugsSorting.sortBy,
                sortOrder: this.state.launchedDrugsSorting.sortOrder,
                filters: {...this.state.launchedDrugsFilter}
            }
        })
        .then((response) => {
            let labelData = response.data.docs;

            this.setState(() => ({
                labelData
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching data.');
        })
    }

    fetchCS(){
        window.axios.get(`/drugs/cs/${this.state.recordId}`, {
            params: {
                sortBy: this.state.clinicalStudiesSorting.sortBy,
                sortOrder: this.state.clinicalStudiesSorting.sortOrder,
                filters: {...this.state.clinicalStudiesFilter}
            }
        })
        .then((response) => {
            let csData = response.data.docs;

            this.setState(() => ({
                csData
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching data.');
        })
    }

    fetchIPEurope(){
        window.axios.get(`/drugs/ip-europe/${this.state.recordId}`, {
            params: {
                sortBy: this.state.ipEuropeSorting.sortBy,
                sortOrder: this.state.ipEuropeSorting.sortOrder,
                filters: {...this.state.ipEuropeFilter}
            }
        })
        .then((response) => {
            let ipEurope = response.data.docs;

            this.setState(() => ({
                ipEurope
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching data.');
        })
    }

    fetchSPC(){
        window.axios.get(`/drugs/spc/${this.state.recordId}`, {
            params: {
                sortBy: this.state.spcSorting.sortBy,
                sortOrder: this.state.spcSorting.sortOrder,
                filters: {...this.state.spcFilter}
            }
        })
        .then((response) => {
            let spcData = response.data.docs;

            this.setState(() => ({
                spcData
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching data.');
        })
    }

    fetchDrugOverview(){
        window.axios.get(`/drugs/overview/${this.state.recordId}`)
        .then((response) => {
            let drugs = response.data.data;

            this.setState(() => ({
                drugOverviewData:drugs
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching data.');
        })
    }

    showApiManufacturingDetails(){
        let apiManufacturing = this.state.selectedApiManufacturingDetail;
        if(apiManufacturing){
            return <>
                <table>
                    <tbody className="detail-column">
                        <tr>
                            <td><span className="title">Active Ingredients </span></td>
                            <td><span className="data">{apiManufacturing['Active Ingredients']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">HOLDER </span></td>
                            <td><span className="data">{apiManufacturing['HOLDER']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Manufacturer Country </span></td>
                            <td><span className="data">{apiManufacturing['Manufacturer Country']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Active US DMF </span></td>
                            <td><span className="data">{apiManufacturing['Active US DMF']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Active Korean DMF </span></td>
                            <td><span className="data">{apiManufacturing['Active Korean DMF']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Active JapaneseDMF </span></td>
                            <td><span className="data">{apiManufacturing['Active JapaneseDMF']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Active COS </span></td>
                            <td><span className="data">{apiManufacturing['Active COS']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">GDUFA Fees Payment </span></td>
                            <td><span className="data">{apiManufacturing['GDUFA Fees Payment']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">FDA Warning Letter </span></td>
                            <td><span className="data">{apiManufacturing['FDA Warning Letter']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">FDA Inspection date </span></td>
                            <td><span className="data">{apiManufacturing['FDA Inspection date']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Mfgr Status </span></td>
                            <td><span className="data">{apiManufacturing['Mfgr Status']}</span></td>
                        </tr>
                    </tbody>
                </table>
            </>
        }else{
            return <p>No Data found.</p>
        }
    }

    showLabelDetails(){
        let label = this.state.selectedLabelDetail;
        if(label){
            return <>
                <table>
                    <tbody className="detail-column">
                        <tr>
                            <td><span className="title">Active Ingredients </span></td>
                            <td><span className="data">{label['Active Ingredients']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Dosage Form </span></td>
                            <td><span className="data">{label['Dosage Form']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Route </span></td>
                            <td><span className="data">{label['Route']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Trade Name </span></td>
                            <td><span className="data">{label['Trade Name']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Manufacturer </span></td>
                            <td><span className="data">{label['Manufacturer']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Strength </span></td>
                            <td><span className="data">{label['Strength']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Appl Type </span></td>
                            <td><span className="data">{label['Appl_Type']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Appl No </span></td>
                            <td><span className="data">{label['Appl_No']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Applicant Full Name </span></td>
                            <td><span className="data">{label['Applicant_Full_Name']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Type </span></td>
                            <td><span className="data">{label['Type']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Marketer </span></td>
                            <td><span className="data">{label['Marketer']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Revision Date </span></td>
                            <td><span className="data">{label['Revision Date']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Country </span></td>
                            <td><span className="data">{label['Country']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Status </span></td>
                            <td><span className="data">{label['Status']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Marketing Sttaus </span></td>
                            <td><span className="data">{label['Marketing Sttaus']}</span></td>
                        </tr>
                    </tbody>
                </table>
            </>
        }else{
            return <p>No Data found.</p>
        }
    }

    showCSDetails(){
        let cs = this.state.selectedCSDetail;
        if(cs){
            return <>
                <table>
                    <tbody className="detail-column">
                        <tr>
                            <td><span className="title">Active Ingredients </span></td>
                            <td><span className="data">{cs['Active Ingredients']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Rank</span></td>
                            <td><span className="data">{cs['Rank']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Title</span></td>
                            <td><span className="data">{cs['Title']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Status</span></td>
                            <td><span className="data">{cs['Status']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Study Results</span></td>
                            <td><span className="data">{cs['Study Results']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Conditions</span></td>
                            <td><span className="data">{cs['Conditions']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Interventions</span></td>
                            <td><span className="data">{cs['Interventions']}</span></td>
                        </tr>
                        <tr>
                            <td><span className="title">Phases</span></td>
                            <td><span className="data">{cs['Phases']}</span></td>
                        </tr>
                    </tbody>
                </table>
            </>
        }else{
            return <p>No Data found.</p>
        }
    }

    fetchSuggestions(e, column, module){

        if(!e){
            this.setState(() => ({
                suggestionsList: []
            }))

            return;
        }

        window.axios.get(`/drugs/fetch-suggestions/${this.state.recordId}`,{
            params: {
                module,
                column
            }
        })
        .then((response) => {
            this.setState(() => ({
                suggestionsList: response.data.data
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching suggestions.');
        })
    }

    render(){
        return <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <a href="#" onClick={this.props.history.goBack} className="backBtn">
                            <i class="fa fa-arrow-left pt-3 backbtn" aria-hidden="true"></i>
                        </a>
                        <a className="my-2 mx-2 text-secondary pull-right" href="#"><i class="fa fa-download" aria-hidden="true"></i></a>

                        {this.state.drugOverviewData &&<a className="my-2 text-primary pull-right" href={`mailto:enquiry@inphamed.in?subject=${this.state.drugOverviewData['Active Ingredients']}`}>Raise a query</a>}
                        <a className="my-2 mx-2 text-secondary pull-right" href="#"><i className="fa fa-bell"></i> Receive alerts</a>
                    </div>
                </div>
                {this.state.drugOverviewData && <div className="row drug-detail">
                    <div className="col">
                        <div className="my-3 p-3 bg-body rounded shadow-sm">
                            <h6 className="border-bottom pb-2 mb-0 mb-3">{this.state.drugOverviewData['Active Ingredients']}</h6>
                            <div className="row">
                                {this.state.drugOverviewData['Trade Name'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">Trade Name : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Trade Name']}</span> 
                                </div>}
                                {this.state.drugOverviewData['Active Ingredients'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">Active Ingredients : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Active Ingredients']}</span>
                                </div>}
                                {this.state.drugOverviewData['Strength'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">Strength : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Strength']}</span>  
                                </div>}
                                {this.state.drugOverviewData['Dosage Form'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">Dosage Form : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Dosage Form']}</span>
                                </div>}
                                {this.state.drugOverviewData['Submission'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">Submission : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Submission']}</span>
                                </div>}
                                {this.state.drugOverviewData['Action Type'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">Action Type : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Action Type']}</span>
                                </div>}
                                {this.state.drugOverviewData['Review Priority'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">Review Priority : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Review Priority']}</span>
                                </div>}
                                {this.state.drugOverviewData['Orphan Status'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">Orphan Status : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Orphan Status']}</span>
                                </div>}
                                {this.state.drugOverviewData['Company'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">Company : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Company']}</span>
                                </div>}
                                {this.state.drugOverviewData['NDA/BLA'] && <div className="col-6 mt-1">
                                    <span className="text-secondary">NDA/BLA : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['NDA/BLA']}</span>
                                </div>  }                              
                            </div>
                            <div className="row">
                                {this.state.drugOverviewData['Status'] && <div className="col-12 mt-1">
                                    <span className="text-secondary">Status : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Status']}</span>
                                </div>}
                                {this.state.drugOverviewData['Therapy area'] && <div className="col-12 mt-1">
                                    <span className="text-secondary">Therapy area : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Therapy area']}</span>
                                </div>}
                                
                                {/* {this.state.drugOverviewData['Comments EUR IP'] && <div className="col-12 mt-1">
                                    <span className="text-secondary">Comments EUR IP : </span> 
                                    <span className="text-default">{this.state.drugOverviewData['Comments EUR IP']}</span>
                                </div>} */}
                            </div>
                        </div>
                        <div className="detail-section">
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    {/* IP USA section */}
                                    {(this.props.region === 'us') && <div className="accordion-item mt-3 mb-2">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                            IP USA
                                        </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                                        <div className="accordion-body">
                                            <p>
                                                {this.state.drugOverviewData['Comments US IP'] && <div className="col-12 mt-1">
                                                    <span className="text-secondary">Analyst Comment : </span> 
                                                    <span className="text-default">{this.state.drugOverviewData['Comments US IP']}</span>
                                                </div>}
                                            </p>
                                        <div className="d-flex text-muted table-wrapper">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Type of patent','ipUS')} className={`border-0 ${this.state.ipUSSorting.sortBy === 'Type of patent' ? this.state.ipUSSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Type of patent</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Type of patent','ipUS')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Type of patent', 'ipUS')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Patent no','ipUS')} className={`border-0 ${this.state.ipUSSorting.sortBy === 'Patent no' ? this.state.ipUSSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Patent no</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Patent no','ipUS')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Patent no','ipUS')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>Active Ingredients</th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Status','ipUS')} className={`border-0 ${this.state.ipUSSorting.sortBy === 'Status' ? this.state.ipUSSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Status</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Status','ipUS')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Status','ipUS')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Estimated Expiry','ipUS')} className={`border-0 ${this.state.ipUSSorting.sortBy === 'Estimated Expiry' ? this.state.ipUSSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Estimated expiry</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Estimated Expiry','ipUS')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Estimated Expiry','ipUS')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>coverage</th>
                                                                <th>Pedeatric exlusivity</th>
                                                                {/* <th></th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.ipUSA.map((ipUS, index) => 
                                                                    <tr key={index}>
                                                                        <td>{ipUS['Type of patent']}</td>
                                                                        <td>{ipUS['Patent no']}</td>
                                                                        <td>{ipUS['Active Ingredients']}</td>
                                                                        <td>{ipUS['Status']}</td>
                                                                        <td>{ipUS['Estimated Expiry'] ? moment(ipUS['Estimated Expiry']).format('YYYY-MM-DD') : ''}</td>
                                                                        <td>{ipUS['coverage']}</td>
                                                                        <td>{ipUS['Pedeatric exlusivity']}</td>
                                                                        {/* <td>
                                                                            <div className="dropdown table-action-dropdown">
                                                                                <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                                                                                    <li><a className="dropdown-item" href="#"><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <li><a className="dropdown-item text-danger" href="#"><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td> */}
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                                                    <ul className="pagination">
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                        </li>
                                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                            <span className="sr-only">Next</span>
                                                        </a>
                                                        </li>
                                                    </ul>
                                                </nav> */}
                                            </div>
                                        </div>
                                    </div>}

                                    {/* IP Europe section */}
                                    {(this.props.region === 'europe') && <div className="accordion-item mt-3 mb-2">
                                        <h2 className="accordion-header" id="drug-ip-europe-heading">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#drug-ip-europe" aria-expanded="false" aria-controls="drug-ip-europe">
                                            IP Europe
                                        </button>
                                        </h2>
                                        <div id="drug-ip-europe" className="accordion-collapse collapse show" aria-labelledby="drug-ip-europe-heading">
                                        <div className="accordion-body">
                                            <p>
                                                {this.state.drugOverviewData['Comments EUR IP'] && <div className="col-12 mt-1">
                                                    <span className="text-secondary">Analyst Comment : </span> 
                                                    <span className="text-default">{this.state.drugOverviewData['Comments EUR IP']}</span>
                                                </div>}
                                            </p>
                                        <div className="d-flex text-muted table-wrapper">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                
                                                                <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Type of patent','ipEurope')} className={`border-0 ${this.state.ipEuropeSorting.sortBy === 'Type of patent' ? this.state.ipEuropeSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Type of patent</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Type of patent','ipEurope')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Type of patent', 'ipEurope')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th width="10%">
                                                                    <span onClick={(e) => this.changeSorting('Patent no','ipEurope')} className={`border-0 ${this.state.ipEuropeSorting.sortBy === 'Patent no' ? this.state.ipEuropeSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Patent no</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Patent no','ipEurope')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Patent no','ipEurope')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th width="20%">Active Ingredients</th>
                                                                <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Status','ipEurope')} className={`border-0 ${this.state.ipEuropeSorting.sortBy === 'Status' ? this.state.ipEuropeSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Status</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Status','ipEurope')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Status','ipEurope')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Estimated Expiry','ipEurope')} className={`border-0 ${this.state.ipEuropeSorting.sortBy === 'Estimated Expiry' ? this.state.ipEuropeSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Estimated Expiry</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Estimated Expiry','ipEurope')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Estimated Expiry','ipEurope')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th width="20%">coverage</th>
                                                                <th width="10%">Pedeatric exlusivity</th>
                                                                {/* <th></th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.ipEurope.map((ipEur, index) => 
                                                                    <tr key={index}>
                                                                        
                                                                        <td>{ipEur['Type of patent']}</td>
                                                                        <td>{ipEur['Patent no']}</td>
                                                                        <td>{ipEur['Active Ingredients']}</td>
                                                                        <td>{ipEur['Status']}</td>
                                                                        <td>{ipEur['Estimated Expiry'] ? moment(ipEur['Estimated Expiry']).format('YYYY-MM-DD') : ''}</td>
                                                                        <td>{ipEur['coverage']}</td>
                                                                        <td>{ipEur['Pedeatric exlusivity']}</td>
                                                                        {/* <td>
                                                                            <div className="dropdown table-action-dropdown">
                                                                                <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                                                                                    <li><a className="dropdown-item" href="#"><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <li><a className="dropdown-item text-danger" href="#"><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td> */}
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                                                    <ul className="pagination">
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                        </li>
                                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                            <span className="sr-only">Next</span>
                                                        </a>
                                                        </li>
                                                    </ul>
                                                </nav> */}
                                            </div>
                                        </div>
                                    </div>}

                                    {/* SPC section */}
                                    {(this.props.region === 'europe') && <div className="accordion-item mt-3 mb-2">
                                        <h2 className="accordion-header" id="drug-spc-heading">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#drug-spc" aria-expanded="false" aria-controls="drug-spc">
                                            SPC
                                        </button>
                                        </h2>
                                        <div id="drug-spc" className="accordion-collapse collapse show" aria-labelledby="drug-spc-heading">
                                        <div className="accordion-body">
                                            <p>
                                                {this.state.drugOverviewData['SPC Comments'] && <div className="col-12 mt-1">
                                                    <span className="text-secondary">SPC Comments : </span> 
                                                    <span className="text-default">{this.state.drugOverviewData['SPC Comments']}</span>
                                                </div>}
                                            </p>
                                        <div className="d-flex text-muted table-wrapper">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Patent','spc')} className={`border-0 ${this.state.spcSorting.sortBy === 'Patent' ? this.state.spcSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Patent</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Patent','spc')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Patent', 'spc')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th width="40%">Active Ingredients</th>
                                                                <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Country','spc')} className={`border-0 ${this.state.spcSorting.sortBy === 'Country' ? this.state.spcSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Country</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Country','spc')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Country','spc')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Est Expiry','spc')} className={`border-0 ${this.state.spcSorting.sortBy === 'Est Expiry' ? this.state.spcSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Est Expiry</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Est Expiry','spc')}  trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Est Expiry','spc')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.spcData.map((spc, index) => 
                                                                <tr key={index}>
                                                                    
                                                                    <td>{spc['Patent']}</td>
                                                                    <td>{spc['Active Ingredients']}</td>
                                                                    <td>{spc['Country']}</td>
                                                                    <td>{moment(spc['Est Expiry']).format('YYYY-MM-DD')}</td>
                                                                </tr>
                                                            )}

                                                            {(this.state.spcData.length < 1) && <tr>
                                                                <td colSpan="4">No data found.</td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                                                    <ul className="pagination">
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                        </li>
                                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                            <span className="sr-only">Next</span>
                                                        </a>
                                                        </li>
                                                    </ul>
                                                </nav> */}
                                        </div>
                                        </div>
                                    </div>}
                                    
                                    {/* API manufacturing section */}
                                    {(true) && <div className="accordion-item mb-2">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            API Manufacturing Status
                                        </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                            <div className="accordion-body">
                                                <div className="d-flex text-muted table-wrapper">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th><input type="checkbox"/></th>
                                                                {/* <th>DMF#</th> */}
                                                                {/* <th>STATUS</th> */}
                                                                {/* <th>TYPE</th> */}
                                                                {/* <th>SUBMIT DATE</th> */}
                                                                
                                                                
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('DMF#')} className={`border-0 ${this.state.sorting.sortBy === 'DMF#' ? this.state.sorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>DMF#</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'DMF#')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('DMF#')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('HOLDER')} className={`border-0 ${this.state.sorting.sortBy === 'HOLDER' ? this.state.sorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Holder</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'HOLDER')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('HOLDER')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Manufacturer Country')} className={`border-0 ${this.state.sorting.sortBy === 'Manufacturer Country' ? this.state.sorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Manufacturer Country</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Manufacturer Country')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Manufacturer Country')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>Active Ingredients</th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Active US DMF')} className={`border-0 ${this.state.sorting.sortBy === 'Active US DMF' ? this.state.sorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Active US DMF</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Active US DMF')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Active US DMF')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Active Korean DMF')} className={`border-0 ${this.state.sorting.sortBy === 'Active Korean DMF' ? this.state.sorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Active Korean DMF</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Active Korean DMF')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Active Korean DMF')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Active JapaneseDMF')} className={`border-0 ${this.state.sorting.sortBy === 'Active JapaneseDMF' ? this.state.sorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Active JapaneseDMF</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Active JapaneseDMF')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Active JapaneseDMF')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Active COS')} className={`border-0 ${this.state.sorting.sortBy === 'Active COS' ? this.state.sorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Active COS</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Active COS')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Active COS')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>GDUFA Fees Payment
                                                                </th>
                                                                <th>FDA Warning Letter
                                                                    
                                                                </th>
                                                                <th>FDA Inspection date
                                                                    
                                                                </th>
                                                                <th>Mfgr Status
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.apiManufacturingData.map((apiManufacturing, index) => 
                                                                <tr key={index}>
                                                                    <td><input type="checkbox"/></td>
                                                                    {/* <td>{apiManufacturing['DMF#']}</td> */}
                                                                    {/* <td>{apiManufacturing['STATUS']}</td> */}
                                                                    {/* <td>{apiManufacturing['TYPE']}</td> */}
                                                                    {/* <td>{apiManufacturing['SUBMIT DATE']}</td> */}
                                                                    
                                                                    <td>{apiManufacturing['DMF#']}</td>
                                                                    <td>{apiManufacturing['HOLDER']}</td>
                                                                    <td>{apiManufacturing['Manufacturer Country']}</td>
                                                                    <td>
                                                                        <a className="highlighted-link" data-bs-toggle="modal" data-bs-target="#api-manufacturing-modal" onClick={() => this.setState(() => ({selectedApiManufacturingDetail:apiManufacturing}))} href="#">
                                                                            {apiManufacturing['Active Ingredients']}
                                                                        </a>    
                                                                    </td>
                                                                    <td>{apiManufacturing['Active US DMF']}</td>
                                                                    <td>{apiManufacturing['Active Korean DMF']}</td>
                                                                    <td>{apiManufacturing['Active JapaneseDMF']}</td>
                                                                    <td>{apiManufacturing['Active COS']}</td>
                                                                    <td>{apiManufacturing['GDUFA Fees Payment'] ? moment(apiManufacturing['GDUFA Fees Payment']).format("YYYY-MM-DD") : ""}</td>
                                                                    <td>{apiManufacturing['FDA Warning Letter']}</td>
                                                                    <td>{apiManufacturing['FDA Inspection date']}</td>
                                                                    <td>{apiManufacturing['Mfgr Status']}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                                                    <ul className="pagination">
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                        </li>
                                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                            <span className="sr-only">Next</span>
                                                        </a>
                                                        </li>
                                                    </ul>
                                                </nav> */}
                                            </div>
                                        </div>
                                    </div>}
                                    
                                    {/* Label Section */}
                                    {(true) && <div className="accordion-item mt-3 mb-2">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                            Launched Drugs
                                        </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
                                        <div className="accordion-body">
                                        <div className="d-flex text-muted table-wrapper">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Dosage Form','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Dosage Form' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Dosage Form</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Dosage Form','launchedDrugs')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Dosage Form', 'launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Route','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Route' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Route</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Route','launchedDrugs')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('Route', 'launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Trade Name','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Trade Name' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Trade Name</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Trade Name','launchedDrugs')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Trade Name','launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>Active Ingredients</th>
                                                                {(this.props.region === 'us') && <th>
                                                                    <span onClick={(e) => this.changeSorting('Manufacturer','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Manufacturer' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Manufacturer</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Manufacturer','launchedDrugs')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Manufacturer','launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Strength','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Strength' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Strength</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Strength','launchedDrugs')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Strength','launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                <th>Country
                                                                </th>
                                                                {(this.props.region === 'us') &&<th>
                                                                    <span onClick={(e) => this.changeSorting('Appl_Type','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Appl_Type' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Appl Type</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Appl_Type','launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'us') &&<th>
                                                                    <span onClick={(e) => this.changeSorting('Appl_No','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Appl_No' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Appl No</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Appl_No','launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'us') &&<th>Applicant_Full_Name
                                                                </th>}
                                                                <th>
                                                                    <span onClick={(e) => this.changeSorting('Marketer','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Marketer' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Marketer</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Marketer','launchedDrugs')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Marketer','launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>
                                                                {(this.props.region === 'us') &&<th>
                                                                    <span onClick={(e) => this.changeSorting('Revision Date','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Revision Date' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Revision Date</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Revision Date','launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'us') &&<th>
                                                                    <span onClick={(e) => this.changeSorting('Marketing Sttaus','launchedDrugs')} className={`border-0 ${this.state.launchedDrugsSorting.sortBy === 'Marketing Sttaus' ? this.state.launchedDrugsSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Marketing Sttaus</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Marketing Sttaus','launchedDrugs')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.labelData.map((label, index) => 
                                                                <tr key={index}>
                                                                    
                                                                    <td width="20%">{label['Dosage Form']}</td>
                                                                    <td width="20%">{label['Route']}</td>
                                                                    <td width="10%">{label['Trade Name']}</td>
                                                                    <td width="20%">
                                                                        <a className="highlighted-link" data-bs-toggle="modal" data-bs-target="#label-detail-modal" onClick={() => this.setState(() => ({selectedLabelDetail:label}))} href="#">
                                                                            {label['Active Ingredients']}
                                                                        </a>
                                                                    </td>
                                                                    {(this.props.region === 'us') &&<td width="20%">{label['Manufacturer']}</td>}
                                                                    <td width="10%">{label['Strength']}</td>
                                                                    <td width="10%">{label['Country']}</td>
                                                                    {(this.props.region === 'us') &&<td width="10%">{label['Appl_Type']}</td>}
                                                                    {(this.props.region === 'us') &&<td width="10%">{label['Appl_No']}</td>}
                                                                    {(this.props.region === 'us') &&<td width="20%">{label['Applicant_Full_Name']}</td>}
                                                                    {(this.props.region === 'us') &&<td width="10%">{label['Type']}</td>}
                                                                    <td width="10%">{label['Marketer']}</td>
                                                                    {(this.props.region === 'us') &&<td width="10%">{label['Revision Date']}</td>}
                                                                    {(this.props.region === 'us') &&<td width="10%">{label['Marketing Sttaus']}</td>}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                                                    <ul className="pagination">
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                        </li>
                                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                            <span className="sr-only">Next</span>
                                                        </a>
                                                        </li>
                                                    </ul>
                                                </nav> */}
                                            </div>
                                        </div>
                                    </div>}
                                    
                                    {/* CS section */}
                                    {(true) && <div className="accordion-item mt-3 mb-2">
                                        <h2 className="accordion-header" id="drug-cs-heading">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#drug-cs" aria-expanded="false" aria-controls="drug-cs">
                                        Clinical Studies
                                        </button>
                                        </h2>
                                        <div id="drug-cs" className="accordion-collapse collapse show" aria-labelledby="drug-cs-heading">
                                        <div className="accordion-body">
                                        <div className="d-flex text-muted table-wrapper">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                {(this.props.region === 'europe') && <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('EudraCT Number','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'EudraCT Number' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>EudraCT Number</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'EudraCT Number','clinicalStudies')} trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('EudraCT Number', 'clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('National Competent Authority','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'National Competent Authority' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>National Competent Authority</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="right" overlay={this.renderFilterPopover('National Competent Authority','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'us') && <th width="10%">
                                                                    <span onClick={(e) => this.changeSorting('Phases','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Phases' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Phases</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Phases','clinicalStudies')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Phases','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'us') && <th width="10%">Title
                                                                </th>}
                                                                <th width="20%">Active Ingredients</th>
                                                                {(this.props.region === 'us') && <th width="10%">
                                                                    <span onClick={(e) => this.changeSorting('Status','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Status' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Status</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Status','clinicalStudies')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Status','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'us') && <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Study Results','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Study Results' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Study Results</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Study Results','clinicalStudies')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Study Results','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'us') && <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Conditions','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Conditions' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Conditions</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Conditions','clinicalStudies')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Conditions','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'us') && <th width="20%">Interventions
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Trial Status','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Trial Status' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Trial Status</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Trial Status','clinicalStudies')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Trial Status','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="20%">Full title of the trial
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="20%">
                                                                    <span onClick={(e) => this.changeSorting('Name of Sponsor','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Name of Sponsor' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Name of Sponsor</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Name of Sponsor','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">Medical condition(s) being investigated
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">
                                                                    <span onClick={(e) => this.changeSorting('Therapeutic area','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Therapeutic area' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Therapeutic area</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Therapeutic area','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">
                                                                    <span onClick={(e) => this.changeSorting('Main objective of the trial','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Main objective of the trial' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Main objective of the trial</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Main objective of the trial','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">
                                                                    <span onClick={(e) => this.changeSorting('Secondary objectives of the trial','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Secondary objectives of the trial' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Secondary objectives of the trial</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Secondary objectives of the trial','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">
                                                                    <span onClick={(e) => this.changeSorting('Human pharmacology (Phase I):','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Human pharmacology (Phase I):' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Human pharmacology (Phase I):</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Human pharmacology (Phase I):','clinicalStudies')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Human pharmacology (Phase I):','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">
                                                                    <span onClick={(e) => this.changeSorting('First administration to humans','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'First administration to humans' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>First administration to humans</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('First administration to humans','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">
                                                                    <span onClick={(e) => this.changeSorting('Bioequivalence study','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Bioequivalence study' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Bioequivalence study</span>
                                                                    <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Bioequivalence study','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">
                                                                    <span onClick={(e) => this.changeSorting('Therapeutic exploratory (Phase II):','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Therapeutic exploratory (Phase II):' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Therapeutic exploratory (Phase II):</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Therapeutic exploratory (Phase II):','clinicalStudies')}  trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Therapeutic exploratory (Phase II):','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">
                                                                    <span onClick={(e) => this.changeSorting('Therapeutic confirmatory (Phase III):','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Therapeutic confirmatory (Phase III):' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Therapeutic confirmatory (Phase III):</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Therapeutic confirmatory (Phase III):','clinicalStudies')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Therapeutic confirmatory (Phase III):','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                                {(this.props.region === 'europe') && <th width="40%">
                                                                    <span onClick={(e) => this.changeSorting('Therapeutic use (Phase IV):','clinicalStudies')} className={`border-0 ${this.state.clinicalStudiesSorting.sortBy === 'Therapeutic use (Phase IV):' ? this.state.clinicalStudiesSorting.sortIconClass + ' sort-icon': 'sort-icon sort-icon-nutral'}`}>Therapeutic use (Phase IV):</span>
                                                                    <OverlayTrigger onToggle={(e) => this.fetchSuggestions(e, 'Therapeutic use (Phase IV):','clinicalStudies')} trigger="click" rootClose placement="left" overlay={this.renderFilterPopover('Therapeutic use (Phase IV):','clinicalStudies')}>
                                                                        <span className="capitalize-text filter-parent-tag" href="#">&nbsp;<i className='fa fa-search'></i></span>
                                                                    </OverlayTrigger>
                                                                </th>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.csData.map((cs, index) => 
                                                                <tr key={index}>
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['EudraCT Number']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['National Competent Authority']}</td>}
                                                                    {(this.props.region === 'us') &&<td>{cs['Phases']}</td>}
                                                                    {(this.props.region === 'us') &&<td>{cs['Title']}</td>}
                                                                    <td> {cs['Active Ingredients']}</td>
                                                                    {(this.props.region === 'us') &&<td>{cs['Status']}</td>}
                                                                    {(this.props.region === 'us') &&<td>{cs['Study Results']}</td>}
                                                                    {(this.props.region === 'us') &&<td>{cs['Conditions']}</td>}
                                                                    {(this.props.region === 'us') &&<td>{cs['Interventions']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Trial Status']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Full title of the trial']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Name of Sponsor']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Medical condition(s) being investigated']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Therapeutic area']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Main objective of the trial']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Secondary objectives of the trial']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Human pharmacology (Phase I):']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['First administration to humans']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Bioequivalence study']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Therapeutic exploratory (Phase II):']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Therapeutic confirmatory (Phase III):']}</td>}
                                                                    {(this.props.region === 'europe') && <td width="20%">{cs['Therapeutic use (Phase IV):']}</td>}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                                                    <ul className="pagination">
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                        </li>
                                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                        <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                            <span className="sr-only">Next</span>
                                                        </a>
                                                        </li>
                                                    </ul>
                                                </nav> */}
                                        </div>
                                        </div>
                                    </div>}

                                    {/* Itigation section */}
                                    <div className="accordion-item mt-3 mb-2">
                                        <h2 className="accordion-header" id="drug-itigation-heading">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#drug-itigation" aria-expanded="false" aria-controls="drug-itigation">
                                            Litigation
                                        </button>
                                        </h2>
                                        <div id="drug-itigation" className="accordion-collapse collapse show" aria-labelledby="drug-itigation-heading">
                                            <div className="accordion-body">
                                                <div className="d-flex text-muted table-wrapper">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Coming soon.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Regulatory section */}
                                    <div className="accordion-item mt-3 mb-2">
                                        <h2 className="accordion-header" id="drug-regulatory-heading">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#drug-regulatory" aria-expanded="false" aria-controls="drug-regulatory">
                                            Regulatory
                                        </button>
                                        </h2>
                                        <div id="drug-regulatory" className="accordion-collapse collapse show" aria-labelledby="drug-regulatory-heading">
                                            <div className="accordion-body">
                                                <div className="d-flex text-muted table-wrapper">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Coming soon.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>}
            </div>
            <ModalComponent title="Api Manufacturing Details" content={this.showApiManufacturingDetails()} className="right" dataBsBackdrop="static" id="api-manufacturing-modal"/>
            <ModalComponent title="Label Details" content={this.showLabelDetails()} className="right" dataBsBackdrop="static" id="label-detail-modal"/>
            <ModalComponent title="CS Details" content={this.showCSDetails()} className="right" dataBsBackdrop="static" id="cs-detail-modal"/>
        </>
    }
}

export default withAlert()(requireAuth(adminLayout(DrugDetailPage)));
