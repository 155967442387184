import React from "react";
import "../../assets/css/login.css"
import { Link } from 'react-router-dom';
import authLayout from "../../hoc/authLayout";
import { connect } from "react-redux"
import { saveLoginUser } from './../../store/actions/authActions';

class LoginPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: ''
        };
    }

    componentDidMount(){
        if(this.props?.login?.isAuthenticated){
            return this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProp, prevState){
        if(this.props?.login?.isAuthenticated){
            return this.props.history.push('/');
        }
    }

    submitLoginForm = async (e) => {
        e.preventDefault()
        this.props.dispatch(saveLoginUser({email: this.state.email, password: this.state.password}))
    }

    render(){
        return <>
        <div className="col-12 window-height-100-per login-page-container">
            <div className="row">
                <div className="col-12 bg-white">
                    <header class="d-flex flex-wrap justify-content-center py-3 border-bottom">
                        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                            <img className="logo-image" alt="potato crm" src={require('../../assets/images/logo.png')} />
                        </a>
                    </header>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-8 col-lg-6 col-xl-4">
                    <form className="login-form">
                        {this.props.response?.status === 'ERROR' && <p className="text-danger">{this.props.response?.payload?.data?.message}</p>}
                        {this.props.response?.status === 'SUCCESS' && <p className="text-success">{this.props.response?.payload?.message}</p>}
                        <div className="d-flex align-items-center my-4">
                            <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
                        </div>
                        {/* <!-- Email input --> */}
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="form3Example3">Email address</label>
                            <input onChange={(e) => this.setState(() => ({email: e.target.value}))} type="email" id="form3Example3" className="form-control form-control-lg"
                            placeholder="Enter a valid email address" />
                            {this.props.response?.payload?.data?.errors?.email && <p className="text-danger">{this.props.response?.payload?.data?.errors?.email}</p>}
                        </div>

                        {/* <!-- Password input --> */}
                        <div className="form-outline mb-3">
                            <label className="form-label" htmlFor="form3Example4">Password</label>
                            <input onChange={(e) => this.setState(() => ({password: e.target.value}))} type="password" id="form3Example4" className="form-control form-control-lg"
                            placeholder="Enter password" />
                            {this.props.response?.payload?.data?.errors?.password && <p className="text-danger">{this.props.response?.payload?.data?.errors?.password}</p>}
                        </div>

                        {/* <div className="d-flex justify-content-between align-items-center">
                            <div className="form-check mb-0">
                            <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                            <label className="form-check-label" htmlFor="form2Example3">
                                Remember me
                            </label>
                            </div>
                            <Link to="/reset-password" className="text-body">Forgot password?</Link>
                        </div> */}

                        <div className="text-center text-lg-start mt-4 pt-2">
                            <Link to="/" type="button" onClick={(e) => this.submitLoginForm(e)} className="btn btn-primary btn-lg">Login</Link>
                            {/* <p className="small fw-bold mt-2 pt-1 mb-0">Don't have an account? <a href="#!"
                                className="link-danger">Register</a></p> */}
                        </div>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-12 bg-white">
                    <footer class="d-flex flex-wrap justify-content-between fixed-bottom align-items-center py-3 bg-white border-top">
                        <div class="col-md-4 d-flex align-items-center">
                            <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                            </a>
                            <span class="text-muted">© 2022 Inphamed</span>
                        </div>
                        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                            <li className="ms-3"><a className="text-muted px-2" href={`mailto:enquiry@inphamed.in`}>Contact us</a></li>
                        </ul>
                    </footer>
                </div>
            </div>
        </div>
        </>
    }
}

const mapStateToProps = (state) => ({ login: state.login, response: state.response })

export default connect(mapStateToProps)(authLayout(LoginPage));