import storage from 'local-storage-fallback'

// getch user details from localstorage
export const getLoggedInUserDetails = () => {
    try {
        let user = storage.getItem('user');
        let isAuthenticated = storage.getItem('isAuthenticated');
        
        if (user !== null) {
            user = JSON.parse(user)
        }

        if (isAuthenticated !== null) {
            isAuthenticated = JSON.parse(isAuthenticated)
        }
        
        return {
            user, isAuthenticated
        };
    } catch (err) {
        return undefined;
    }
}

// get selected region detail
// default value is us.
export const getSelectedRegionDetail = () => {
    try {
        let selectedRegion = storage.getItem('selectedRegion') || 'us';
        return selectedRegion;

    }catch(err){
        return 'us';
    }
}