import React from 'react';
import { withAlert } from 'react-alert';

class AddDataForm extends React.Component {
    constructor(props){
        super(props);

        this.state= {
            loading: false,
            uploadedFile: null,
            formValidationErrors: [],
            region: ''
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.clickEvent !== this.props.clickEvent){
            this.saveLead();
        }
    }

    componentDidMount(){
        this.changeInputEvent();
    }

    saveLead(){

        // Create an object of formData
        const formData = new FormData();

        if(this.state?.uploadedFile){
            // Update the formData object
            formData.append(
                "file",
                this.state?.uploadedFile,
                this.state?.uploadedFile?.name
            );
        }

        formData.append("region", this.state.region);

        this.props.handleSaveLoadingStatus(true);

        window.axios.post('/upload-data', formData) 
        .then((response) => {
            this.props.handleSaveLoadingStatus(false);

            this.setState(() => ({
                formValidationErrors: []
            }));

            this.props.alert.success(response.data.message);
        }).catch((error) => {
            let errorData = error.response.data;

            this.setState(() => ({
                formValidationErrors: errorData.errors
            }));

            this.props.alert.error(errorData.message);

            this.props.handleSaveLoadingStatus(false);
        });
    }

    changeInputEvent(){
        const input = document.getElementById('input');

        input.addEventListener('change', () => {
            this.setState(() => ({
                uploadedFile: input.files[0]
            }))
        });
    }

    render(){
        return <>
            <form>
                <div className="row">
                    <div className="col-md-12 my-2">
                        <div className="form-group">
                            <label className="my-1">File *</label>
                            <input id="input" type="file" className="form-control"/>
                            <span className='text-danger'>{this.state.formValidationErrors?.file}</span>
                        </div>
                        <div className="form-group">
                            <label className="my-1">Region *</label> 
                            <br/>
                            <input type="radio" onChange={(e) => this.setState(() => ({region: e.target.value}))} id="usRegion" name="region" value="us"/> <label for="usRegion">Us</label>
                            <br/>
                            <input type="radio" onChange={(e) => this.setState(() => ({region: e.target.value}))} id="europeRegion" name="region" value="europe"/> <label for="europeRegion">Europe</label>
                            <br/>
                            <span className='text-danger'>{this.state.formValidationErrors?.region}</span>
                        </div>
                    </div>
                </div>
            </form>
        </>
    }
}

export default withAlert()(AddDataForm);