import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk';
import { loginReducer } from "./reducers/loginReducer";
import {regionReducer} from "./reducers/regionReducer";
import { responseReducer } from "./reducers/responseReducer";
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
    login: loginReducer,
    response: responseReducer,
    region: regionReducer
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store;