import { getSelectedRegionDetail } from "../storage"

const initialLoginState = { selectedRegion: getSelectedRegionDetail() };

export const regionReducer = (state = initialLoginState, action) => {
    switch(action.type){
        case 'SET_REGION': {
            return {
                selectedRegion: action.region,
            }
        }

        default: {
            return state
        }
    }
}