import 'font-awesome/css/font-awesome.min.css';
import './assets/css/app.css';
import DrugListPage from './pages/DrugListPage';
import DrugDetailPage from './pages/DrugDetailPage';
import TypographyPage from './pages/TypographyPage'
import LoginPage from './pages/auth/LoginPage'
import ResetPassword from './pages/auth/ResetPassword';
import ProfilePage from './pages/profile/ProfilePage';
import ChangePasswordPage from './pages/profile/ChangePasswordPage';
import UserPreferencesPage from './pages/profile/UserPreferencesPage'
import UserListPage from './pages/user/UserListPage'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {useSelector} from 'react-redux'
window.axios = require("axios");
window.axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

function App(props) {

    const user = useSelector(state => state.login.user)

    window.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
        "Authorization": `Bearer ${user?.token}`
    };

    return (
        <Router>
            <Switch>
                <Route exact index path='/' component={ props => <DrugListPage {...props} /> } />
                <Route exact path='/login' component={ props => <LoginPage {...props} /> } />
                <Route exact path='/reset-password' component={ props => <ResetPassword {...props} />} />
                <Route exact path='/profile' component={ props => <ProfilePage {...props} />} />
                <Route exact path='/change-password' component={ props => <ChangePasswordPage {...props}  />} />
                <Route exact path='/preferences' component={ props => <UserPreferencesPage {...props} />} />
                <Route exact path='/typography' component={props => <TypographyPage {...props} />} />
                <Route exact path='/detail' component={ props => <DrugDetailPage {...props} /> } />
                <Route exact path='/users' component={ props => <UserListPage {...props} /> } />
            </Switch>  
        </Router>
    )
}

export default App;
