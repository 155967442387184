import React from "react";
import { changeSelectedRegion } from './../store/actions/regionActions';
import { connect } from "react-redux"

class Header extends React.Component {
    constructor(props){
        super(props)

        this.state = {

        }
    }

    render(){
        return <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top border-bottom">
            <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                        <li className="nav-item pad-5">
                            <label htmlFor="exampleInputEmail1" className="form-label">Region</label>
                            <select value={this.props.region} onChange={(e) => this.props.dispatch(changeSelectedRegion(e.target.value)) }>
                                <option value="us">US</option>
                                <option value="europe">Europe</option>
                            </select>
                        </li>
                        {(this.props?.login?.user?.isSuperAdmin === true) && <li className="nav-item"><a data-bs-toggle="modal" data-bs-target="#add-lead-modal"  className="nav-link highlighted-text" href="#!"><i className="fa fa-cloud-upload" aria-hidden="true"></i>&nbsp;Upload Data</a></li>}
                        {/* <li className="nav-item dropdown notifications">
                            <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bell"></i></a>
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="#!">Action</a>
                                <a className="dropdown-item" href="#!">Another action</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#!">Something else here</a>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    }
}

const mapStateToProps = (state) => ({region: state.region?.selectedRegion, login: state.login })

export default connect(mapStateToProps)(Header);