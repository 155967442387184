const initialErrorState = {
    payload: null,
    status: null
}

export const responseReducer = (state = initialErrorState, action) => {
    switch(action.type){
        case "ERROR_STUFF": {
            return {
                payload: action.payload.response,
                status: 'ERROR'
            }
        }

        case "SUCCESS_STUFF": {
            return {
                payload: action.payload.data,
                status: 'SUCCESS'
            }
        }

        default: {
            return state
        }
    }
}