import { getLoggedInUserDetails } from "../storage"


const initialLoginState = getLoggedInUserDetails() ? getLoggedInUserDetails() : {user : null, isAuthenticated : false}

export const loginReducer = (state = initialLoginState, action) => {
    switch(action.type){
        case 'LOGIN': {
            return {
                user: action.user,
                isAuthenticated: action.isAuthenticated
            }
        }

        case 'LOGOUT': {
            return {
                user: null,
                isAuthenticated: false
            }
        }

        default: {
            return state
        }
    }
}