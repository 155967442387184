import storage from 'local-storage-fallback'

export const flushUserToken = () => {
    return (dispatch) => {
        storage.removeItem('user')
        storage.removeItem('isAuthenticated')

        dispatch({
            type: 'LOGOUT',
        })
    }
}

export const logoutUser = (token) => {
    return (dispatch) => {
        window.axios.post('/auth/logout', token)
        .then((response) => {
            storage.removeItem('user')
            storage.removeItem('isAuthenticated')

            dispatch({
                type: 'LOGOUT',
            })

            // dispatch success message
            dispatch({
                type: 'SUCCESS_STUFF',
                payload: response
            })
        })
        .catch((error) => {
            // dispatch error message
            dispatch({
                type: 'ERROR_STUFF',
                payload: error
            })
        })
    }
}

export const saveLoginUser = (user) => {
    return (dispatch) => {

        window.axios.post('auth/login', {
            email: user?.email,
            password: user?.password
        })
        .then((response) => {

            const data = response.data

            storage.setItem('user', JSON.stringify(data))
            storage.setItem('isAuthenticated', true)

            // dipatch save login user info
            dispatch({
                type: 'LOGIN',
                user: data,
                isAuthenticated: true
            })

            // dispatch success message
            dispatch({
                type: 'SUCCESS_STUFF',
                payload: response
            })
        })
        .catch((error) => {
            storage.removeItem('user')
            storage.removeItem('isAuthenticated')
            // dipatch save login user info
            dispatch({
                type: 'LOGIN',
                user: null,
                isAuthenticated: false
            })
            
            // dispatch error message
            dispatch({
                type: 'ERROR_STUFF',
                payload: error
            })
        })
    }
}