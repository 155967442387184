import React from "react";
import adminLayout from "./../hoc/adminLayout";
import requireAuth from "../hoc/requireAuth";
import "./../assets/css/lead-list.css";
import { withAlert } from 'react-alert';
import { NavLink } from "react-router-dom";
// import { changeSelectedRegion } from './../store/actions/regionActions';

class DrugListPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            drugs: [],
            loading: false,
            filters: {
                "Active Ingredients" : "",
                "Trade Name" : "",
                "Company" : "",
                "Status" : "",
                "Dosage Form" : "",
                "Route" : ""            }
        }
    }

    changeFilterInputBox(columnName, value){
        let filters = this.state.filters;

        filters[columnName] = value;

        this.setState(() => ({
            filters
        }))
    }

    componentDidMount(){
        this.fetchDrugOverview();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.filters !== this.state.filters){
            this.fetchDrugOverview();
        }

        if(prevProps.region !== this.props.region && this.props.region){
            this.fetchDrugOverview();
        }
    }

    resetFilters(){
        this.setState(() => ({
            filters: {
                "Active Ingredients" : "",
                "Trade Name" : "",
                "Company" : "",
                "Status" : "",
                "Dosage Form" : "",
                "Route" : "",            }
        }));
    }

    applyFilters(){
        this.fetchDrugOverview();
    }   

    fetchDrugOverview(){
        this.setState(() => ({
            loading: true
        }))
        window.axios.get('/drugs/overview', {
            params: {
                ...this.state.filters,
                region: this.props.region
            }
        })
        .then((response) => {
            let drugs = response.data.data;

            this.setState(() => ({
                drugs,
                loading: false
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching data.');
            this.setState(() => ({
                loading: false
            }))
        })
    }

    render(){
        return <>
            <div className="table-container lead-list">
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-0">Drugs <a href="#" data-bs-toggle="modal" data-bs-target="#filters-modal" className="text-primary pull-right"><i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters</a></h5>
                    </div>
                </div>
              
                <div className="d-flex text-muted">
                    <table width="100%" className="table">
                        <thead>
                            <tr>
                                <th><input type="checkbox"/></th>
                                <th>Active Ingredients</th>
                                <th>Company</th>
                                <th>Strength</th>
                                <th>Therapy area</th>
                                {(this.props.region === 'us') &&<th>Submission</th>}
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.drugs.map((drug,index) => 
                                <tr key={index}>
                                    <td><input type="checkbox"/></td>
                                    <td className="lead-primary-column">
                                        <p className="user-name">
                                            <NavLink tag="a" to={`/detail?drug=${drug['_id']}`}>
                                                {drug['Trade Name']}
                                            </NavLink>
                                        </p>
                                        <p className="company-name">
                                            <NavLink className="highlighted-link" tag="a" to={`/detail?drug=${drug['_id']}`}>
                                                {drug['Active Ingredients']}
                                            </NavLink>
                                        </p>
                                    </td>
                                    <td className="lead-primary-contact-column" >
                                        <p>{drug['Company']}</p>
                                    </td>
                                    <td>{drug['Strength']}</td>
                                    <td>{drug['Therapy area']}</td>
                                    {(this.props.region === 'us') &&<td>{drug['Submission']}</td>}
                                    <td>{drug['Status']}</td>
                                </tr>
                            )}
                            {!this.state.drugs.length && <tr>
                                <td colSpan={7}>No drug data available.</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                {/* <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                    <ul className="pagination">
                        <li className="page-item">
                        <a className="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </a>
                        </li>
                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item">
                        <a className="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </a>
                        </li>
                    </ul>
                </nav> */}
            </div>

            {/* filters */}
            <div className="modal fade" data-bs-backdrop="static" id="filters-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Drug Filters</h5>
                        <button type="button" className="btn-close text-secondary" data-bs-dismiss="modal" aria-label="Close">
                        <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Active Ingredients</label>
                                    <input type="email" value={this.state.filters['Active Ingredients']} onChange={(e) => this.changeFilterInputBox("Active Ingredients", e.target.value) } className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Trade Name</label>
                                    <input type="email" value={this.state.filters['Trade Name']} onChange={(e) => this.changeFilterInputBox("Trade Name", e.target.value) } className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Company</label>
                                    <input type="email" value={this.state.filters['Company']} onChange={(e) => this.changeFilterInputBox("Company", e.target.value) } className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Status</label>
                                    <input type="email" value={this.state.filters['Status']} onChange={(e) => this.changeFilterInputBox("Status", e.target.value) } className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Dosage Form</label>
                                    <input type="email" value={this.state.filters['Dosage Form']} onChange={(e) => this.changeFilterInputBox("Dosage Form", e.target.value) } className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Route</label>
                                    <input type="email" value={this.state.filters['Route']} onChange={(e) => this.changeFilterInputBox("Route", e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={(e) => this.resetFilters()} className="btn btn-secondary">Reset</button>
                        <button type="button" onClick={(e) => this.applyFilters()} className="btn btn-default">Apply Filter {this.state.loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default withAlert()(requireAuth(adminLayout(DrugListPage)));