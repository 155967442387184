import React from "react"
import { connect } from "react-redux"
import { flushUserToken } from './../store/actions/authActions';

const requireAuth = (ChildComponent) => {
    class AuthHoc extends React.Component {

        constructor(props){
            super(props)
        }

        componentDidMount(){
            this.checkAuthToken();
            this.checkAuth();
        }

        componentDidUpdate(prevProps, PrevState){
            if(!this.props?.login?.isAuthenticated){
                this.checkAuth();
            }
        }

        checkAuth(){
            if(!this.props.login.isAuthenticated){
                return this.props.history.push('/login');
            }
        }

        checkAuthToken(){
            window.axios.post('/auth/check')
            .catch((error) => {
                this.props.dispatch(flushUserToken());
            })
        }

        render(){
            return (
                <ChildComponent {...this.props} />
            )
        }
    }
    
    const mapStateToProps = (state) => ({ login: state.login, response: state.response, user: state.user, region: state.region?.selectedRegion })
    
    return connect(mapStateToProps)(AuthHoc);
}

export default requireAuth;