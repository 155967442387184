import React from "react";
import adminLayout from "./../../hoc/adminLayout";
import requireAuth from './../../hoc/requireAuth'
import { withAlert } from 'react-alert';
import debounce from 'lodash.debounce';

class UserListPage extends React.Component {
    constructor(props){
        super(props);
        this.fetchUsers = debounce(this.fetchUsers.bind(this), 1000);
        this.state = {
            users: [],
            pagination: {
                perPage: 100,
                page: 1,
                total: null,
                search: ""
            }
        }
    }

    componentDidMount(){
        this.fetchUsers();
    }

    fetchUsers(search = ''){
        window.axios.get(`/users`,{
            params: {
                perPage: this.state.pagination.perPage,
                page: this.state.pagination.page,
                search
            }
        })
        .then((response) => {
            this.setState(() => ({
                users: response.data
            }))
        })
        .catch((error) => {
            this.props.alert.error('Something went wrong while fetching users list.');
        })
    }

    printRows(){
        if(!this.state.users){
            return <tr>
                <td colSpan={5}>No users data found.</td>
            </tr>
        }

        return this.state.users.map((user, index) => 
            <tr key={index}>
                <td>{user.fullName}</td>
                <td>{user.email}</td>
                <td>{user.mobile}</td>
                <td>{user.isSuperAdmin ? 'Yes': 'No'}</td>
                <td>{user.lastLoginDate}</td>
            </tr>
        )
    }

    render(){
        return <>
            <div className="table-container lead-list">
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-0">Users</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <input onChange={(e) => this.fetchUsers(e.target.value)} placeholder="Search users by name, email or mobile" className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="d-flex text-muted my-2">
                    <table width="100%" className="table"> 
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Is superadmin ?</th>
                                <th>Last Logged in At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.printRows()
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    }
}

export default withAlert()(requireAuth(adminLayout(UserListPage)));